const round = (value) => {
    return isFinite(value) && value || 0;
}

const telHyphen = (str)=> {
    if(str !== undefined && str !== null && str !== '') {
        str = str.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);
    }
    return str;
}

const isEmpty = (objValue) => {
    if (objValue === undefined || objValue == null) {
        return true;
    } else {
        return (String(objValue)).length == 0;
    }
}

const htmlConverter = (str) => {
    if(str !== undefined && str !== null && str !== '') {
        str = String(str);
        str = str.replace(/<script[^>]*>([\S\s]*?)<\/script>/gmi, '');
        str = str.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gmi, '');

        const element = document.createElement('div');
        element.innerHTML = str;
        str = element.textContent;
        element.textContent = '';
    }
    return str;
}

const htmlDelete = (str) => {
    if(str !== undefined && str !== null && str !== '') {
        str = String(str);
        str = str.replace(/<[^>]*>?/g, '');
    }
    return str;
}

const companyGbnStr = (code) => {
    switch (code) {
        case 'C01':
            return '요식업'
        case 'C03':
            return '의료원'
        case 'C06':
            return '생활'
        case 'C11':
            return '건설/제조'
        case 'C12':
            return '현대차영업소'
        default:
            return '정보없음'
    }
}

const companyGbnCode = (code) => {
    switch (code) {
        case 'food':
            return 'C01'
        case 'medical':
            return 'C03'
        case 'life':
            return 'C06'
        case 'hyundaicar':
            return 'C12'
        default:
            return ''
    }
}

const companyGbnType = (code) => {
    switch (code) {
        case 'C01':
            return 'food'
        case 'C03':
            return 'medical'
        case 'C06':
            return 'life'
        case 'C12':
            return 'hyundaicar'
        default:
            return ''
    }
}

const areaGbnStr = (code) => {
    switch (code) {
        case 'jj':
            return '전주시'
        case 'wj':
            return '완주군'
        case 'is':
            return '익산시'
        case 'gs':
            return '군산시'
        case 'gk':
            return '김제시'
        case 'other':
            return '기타'
        default:
            return ''
    }
}

const areaGbnCode = (code) => {
    switch (code) {
        case '전주시':
            return 'jj'
        case '완주군':
            return 'wj'
        case '익산시':
            return 'is'
        case '군산시':
            return 'gs'
        case '김제시':
            return 'gk'
        case '기타':
            return 'other'
        default:
            return ''
    }
}

export {
    round,
    telHyphen,
    isEmpty,
    htmlConverter,
    htmlDelete,
    companyGbnStr,
    companyGbnType,
    companyGbnCode,
    areaGbnStr,
    areaGbnCode
}
