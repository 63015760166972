<template>
  <div class="informationMap">
    <div class="contentsTitle colorGrey2">후원의집 전체현황
      <div class="listTotal">총 <span class="bold colorPrimary2">{{houseCount}}</span>개의 후원의집이 있습니다</div>
    </div>
    <div v-if="list.length" class="gridList houseList">
      <div
        v-for="(item, index) in list"
        :key="index"
        class="item">
          <router-link :to="{ name: 'informationDetail', params: {id: item.tr_seq} }">
            <div class="img" :style="item.orgfile && {backgroundImage: `url('https://house.hyundai-motorsfc.com/UpData/introduce/${item.orgfile}')`}">
              <div v-if="!item.orgfile" class="title">{{ item.company }}</div>
            </div>
            <div class="title">{{ item.company }}</div>
            <div class="type">{{ item.company_gbn }}</div>
            <div class="area">{{ item.newaddr }}</div>
          </router-link>
          <div class="tel">
            <a :href="`tel:${item.tel}`">{{ item.tel }}</a>
          </div>

      </div>
    </div>
    <div v-else class="noListData">데이터가 없습니다</div>
    <Pagination v-bind:total="houseCount" v-bind:pageDisplayCount="pageCount" @onChange="pageChange" />
    <ListFilter :types="searchTypes" @onSearch="searchList" />
  </div>
</template>

<script>
import Pagination from '@/components/Common/Pagination'
import ListFilter from '@/components/Common/ListFilter'
import {companyGbnStr, isEmpty, telHyphen} from '@/lib';
import http from '@/api';

export default {
  name: 'InformationAll',
  components: {
    Pagination,
    ListFilter
  },
  created () {
    this.$store.commit('setSubTopData', {
      title: '전체현황',
      desc: '전북 후원의집 소개합니다.'
    })
  },
  mounted () {
    this.init()
  },
  data () {
    return {
      pageNum: 1,
      houseCount: 0,
      pageCount: 0,
      notice: [],
      list: [],
      searchTypes: [
        {
          type: 'company',
          name: '회사'
        }
      ],
      searchpart: '',
      searchtext: ''
    }
  },
  methods: {
    async init () {
      this.houseListCalls();
    },
    pageChange (page) {
      this.pageNum = page;
      this.houseListCalls();
    },
    searchList (data) {
      this.searchpart = data.type
      this.searchtext = data.word;

      this.houseListCalls('search');
    },
    houseListCalls(key) {
      let url = `/main/house?page=${this.pageNum}`;

      if (!isEmpty(this.searchtext)){
        url += `&searchpart=${this.searchpart}&searchtext=${this.searchtext}`;
      }

      http.get(url)
        .then(res => {
          let obj = res.data.data;
          this.houseCount = res.data.total;
          this.pageCount = res.data.per_page;

          Object.entries(obj).forEach(([, value]) => {
            if(!isEmpty(value.tel) && !value.tel.includes('-')) {
              value.tel = telHyphen(value.tel)
            }

            value.company_gbn = companyGbnStr(value.company_gbn);
          });

          this.list = obj;
        });
    }
  }
}
</script>
