<template>
  <div class="map">
    <div id="map"></div>
    <div ref="mapOverlay" class="mapOverlay"></div>
  </div>
</template>

<script>

import {isEmpty} from '@/lib';

export default {
  name: 'KakaoMap',
  props: [
    'searchList',
    'type'
  ],
  watch: {
    searchList() {
      this.init()
    }
  },
  created() {
  },
  mounted () {
    this.init()
  },
  data() {
    return {
      map: null,
      list: [],
      selectedMarker: null,
    }
  },
  methods: {
    async init () {
      if (window.kakao && window.kakao.maps) {
        this.loadMap()
      } else {
        this.loadScript()
      }
    },
    loadScript () {
      // 카카오맵 앱키 > javascript 키 (localhost), 사이트상 키 8f5a0abe7164ad86f19016790a2a2ff9
      const key = '1ea443903321b3dcdd2f971151f1d16d'
      const script = document.createElement('script')
      script.src = `//dapi.kakao.com/v2/maps/sdk.js?appkey=${key}&autoload=false`
      script.onload = () => window.kakao.maps.load(this.loadMap)
      document.head.appendChild(script)
    },
    loadMap () {
      const container = document.getElementById('map')
      const options = {
        center: new window.kakao.maps.LatLng(35.8243499, 127.1477799),
        level: 7
      }
      this.map = new window.kakao.maps.Map(container, options)
      this.loadMarker()
    },
    loadMarker () {
      let list = this.searchList
        ? this.searchList
        : this.list
      const imgSrc1 = require('@/assets/img/marker1.png') // 디폴트
      const imgSrc2 = require('@/assets/img/marker2.png') // 현대차지점
      const imgSize = new window.kakao.maps.Size(38, 64)
      const imgOption = { offset: new window.kakao.maps.Point(19, 64) }
      const bounds = new window.kakao.maps.LatLngBounds();

      list.forEach((obj, index) => {
        const imgSrc = obj.company_gbn === 'C12' ? imgSrc2 : imgSrc1
        const markerPosition = new window.kakao.maps.LatLng(obj.x_coord, obj.y_coord)
        const markerImage = new window.kakao.maps.MarkerImage(imgSrc, imgSize, imgOption)
        const marker = new window.kakao.maps.Marker({
          position: markerPosition,
          image: markerImage,
          clickable: true
        })
        marker.setMap(this.map);
        bounds.extend(markerPosition);

        const infowindow = this.maketContent(obj);

        window.kakao.maps.event.addListener(marker, 'click', () => {
          if(!isEmpty(this.selectedMarker)) {
            this.makeOutListener(this.selectedMarker);
          }

          infowindow.open(this.map, marker);
          this.selectedMarker = infowindow;
        })

        if(isEmpty(this.type)) {
          this.map.setBounds(bounds);
        }
      })
    },
    makeOutListener(infowindow) {
      infowindow.close();
    },
    maketContent(obj) {
      const content = `
        <a href="/information/detail/${obj.tr_seq}">
          <div class="mapInfowindow">
            <div class="img">
            <img src="https://house.hyundai-motorsfc.com/UpData/introduce/${obj.orgfile}" />
            </div>
            <div class="name">${obj.company}</div>
          </div>
        </a>`

      const infowindow = new window.kakao.maps.InfoWindow({
        content: content,
        removable: true
      })
      return infowindow;
    }
  }
}

</script>

<style scoped>
.map {
  height: 680px;
  border-radius: 16px;
  overflow: hidden;
}
#map {
  height: 100%;
}
@media (max-width: 767px) {
  .map {
    height: 550px;
  }
}
</style>
