<template>
  <div class="partnerPage">
    <Top />
    <div class="container">
      <router-view class="contents"></router-view>
    </div>
<!--    <PremiumList v-if="isPremium" />-->
  </div>
</template>

<script>
import Top from '@/components/Common/Subtop'
import PremiumList from '@/components/Common/PremiumList'

export default {
  name: 'PartnerView',
  components: {
    Top,
    // PremiumList
  },
  computed: {
    isPremium () {
      return !this.$route.meta.noPremium
    }
  }
}
</script>
