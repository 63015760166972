<template>
  <div class="informationMap">
    <div class="informationTab typeTab">
      <div class="pcOnly">
        <button
          v-for="(area, index) in zone"
          :key="index"
          :data-type="area.id"
          type="button"
          class="btnTab"
          :class="{active: isActive(area.id)}"
          @click="changeArea(area.id)">
          {{ area.name }}
        </button>
      </div>
      <div class="mobileOnly">
        <select class="input" @change="onChange" v-model="areaCode">
          <option
            v-for="(area, index) in zone"
            :key="index"
            :value="area.id">
            {{ area.name }}
          </option>
        </select>
      </div>
    </div>
    <div class="contentsTitle colorGrey2">후원의집 지역별 분류
      <div class="listTotal">총 <span class="bold colorPrimary2">{{houseCount}}</span>개의 후원의집이 있습니다</div>
    </div>
    <div v-if="list.length" class="gridList houseList">
      <div
        v-for="(item, index) in list"
        :key="index"
        class="item">
          <router-link :to="{ name: 'informationDetail', params: {id: item.tr_seq} }">
            <div class="img" :style="item.orgfile && {backgroundImage: `url('https://house.hyundai-motorsfc.com/UpData/introduce/${item.orgfile}')`}">
              <div v-if="!item.orgfile" class="title">{{ item.company }}</div>
            </div>
            <div class="title">{{ item.company }}</div>
            <div class="type">{{ item.company_gbn_txt }}</div>
            <div class="area">{{ item.area_gbn }}</div>
          </router-link>
          <div class="tel">
            <a :href="`tel:${item.tel}`">{{ item.tel }}</a>
          </div>
      </div>
    </div>
    <div v-else class="noListData">데이터가 없습니다</div>
    <Pagination v-bind:total="houseCount" v-bind:pageDisplayCount="pageCount" @onChange="pageChange" />
    <ListFilter :types="searchTypes" :reset="searchReset" @onSearch="searchList" />
  </div>
</template>

<script>
import Pagination from '@/components/Common/Pagination'
import ListFilter from '@/components/Common/ListFilter'
import {areaGbnStr, companyGbnStr, isEmpty, telHyphen} from '@/lib';
import http from '@/api';

export default {
  name: 'InformationArea',
  components: {
    Pagination,
    ListFilter
  },
  computed: {
    filteredList () {
      const area = this.$route.params.area
      return this.list.filter(x => {
        return area === 'all' ? x : x.area === area
      })
    }
  },
  created () {
    this.$store.commit('setSubTopData', {
      title: '지역별 분류',
      desc: '전북 후원의집 소개합니다.'
    })
  },
  mounted () {
    this.init()
  },
  data () {
    return {
      areaCode: this.$route.params.area,
      pageNum: 1,
      houseCount: 0,
      pageCount: 0,
      zone: [
        {
          id: 'all',
          name: '전체'
        },
        {
          id: 'jj',
          name: '전주'
        },
        {
          id: 'wj',
          name: '완주'
        },
        {
          id: 'is',
          name: '익산'
        },
        {
          id: 'gs',
          name: '군산'
        },
        {
          id: 'gk',
          name: '김제'
        },
        {
          id: 'other',
          name: '기타'
        }
      ],
      list: [],
      searchTypes: [
        {
          type: 'company',
          name: '회사'
        }
      ],
      searchpart: '',
      searchtext: '',
      searchReset: false,
    }
  },
  watch: {
    $route(to, form) {
      this.areaCode = to.params.area;
      this.searchtext = '';
      this.searchReset = true;
      this.pageNum = 1;
      this.houseListCalls();
    }
  },
  methods: {
    async init () {
      this.houseListCalls();
    },
    isActive (area) {
      return area === this.$route.params.area
    },
    onChange (e) {
      const area = e.target.value
      this.changeArea(area)
    },
    changeArea (area) {
      if (!this.isActive(area)) {
        this.$router.push({
          params: {
            area: area
          }
        })
      }
    },
    pageChange (page) {
      this.pageNum = page;
      this.houseListCalls();
    },
    searchList (data) {
      this.searchpart = data.type
      this.searchtext = data.word;

      this.houseListCalls('search');
    },
    houseListCalls(key) {
      let url = `/main/house?page=${this.pageNum}`;

      // 지역별
      if (isEmpty(this.searchtext) && !isEmpty(this.areaCode)){
        let type = areaGbnStr(this.areaCode);
        url += `&searchpart=area_gbn&searchtext=${type}`;
      }

      // 검색
      if (!isEmpty(this.searchtext)){
        url += `&searchpart=${this.searchpart}&searchtext=${this.searchtext}`;
      }

      http.get(url)
        .then(res => {
          let obj = res.data.data;
          this.houseCount = res.data.total;
          this.pageCount = res.data.per_page;

          Object.entries(obj).forEach(([, value]) => {
            if(!isEmpty(value.tel) && !value.tel.includes('-')) {
              value.tel = telHyphen(value.tel)
            }

            value.company_gbn_txt = companyGbnStr(value.company_gbn);
          });

          this.list = obj;
          this.searchReset = false;
        });
    }
  }
}
</script>
