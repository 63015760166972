<template>
  <div class="faq">
    <div class="contentsTitle colorGrey2">FAQ</div>
    <Accordion :list="list" />
  </div>
</template>

<script>
import Accordion from '@/components/Common/Accordion'
import http from '@/api';

export default {
  name: 'HouseFaq',
  components: {
    Accordion
  },
  created () {
    this.$store.commit('setSubTopData', {
      title: 'FAQ',
      desc: '전북 후원의집에 대한 궁금한 것을 알려드립니다'
    })
  },
  mounted () {
    this.init()
  },
  data () {
    return {
      list: []
    }
  },
  methods: {
    init () {
      // this.list = [
      //   {
      //     question: '후원의집 신청은 언제 받나요?',
      //     answer: `<p>후원의집 신청 절차는 본 홈페이지<br>- 후원의집 가입절차( https://house-test.hyundai-motorsfc.com/house/signup )를 보시면 자세히 설명이 되어 있습니다.</p>
      //     <br>
      //     <p>다시한번, 간단히 설명드리면</p>
      //     <br>
      //     <p>1. 후원의집 신청은 년중 아무때나 후원의집 홈페이지를 통해 신청이 가능 합니다.</p>
      //     <p>2. 후원의집 접수 및 조치는 1년에 1회 년초 에 이루어 집니다.</p>
      //     <p>즉, 전년도 신청이나 접수된 업소 리스트를 가지고 년초 1월 ~ 3월까지 구단에서 방문하여 후원의집 적합성 여부를 확인한 후 후원의집 제휴를 시작하게 됩니다.</p>
      //     <p>3. 후원의집 제휴가 된 이유 최초 홍보 현수막 및 할인권을 최초 지급해드리며,<br>이후 후원의집 비품(현판, 할인권 케이스, 기타 의류 등 구단 지원품)은 순차적으로 지급됩니다.</p>
      //     <p>4. 후원의집 홈페이지 등 후원의집에 대한 구단 홍보 지원도 마찬가지로 진행됩니다.</p>
      //     <br>
      //     <p>감사합니다.</p>`,
      //     active: false
      //   },
      //   {
      //     question: '	후원의집 신청업소의 자격은 어떻게 되나요?',
      //     answer: `<p>전북현대 후원의집 신청업소 자격은 아래와 같습니다.</p>
      //     <br>
      //     <p>1. 첫번째, 고객과 접하는 사업체(업소)를 반드시 가지고 있어야 합니다.</p>
      //     <p>▲ 사업체 규모에 대해서는 크게 구분하지 않습니다.</p>
      //     <p>단, 구단의 홈경기 홍보물을 부착하여 홍보지원해줄 수 있는 곳이어야 합니다.</p>
      //     <p>▲ 사업체 업종에 대해서는 일부 제한하고 있습니다.</p>
      //     <p>즉, 상식적인 선에서 사회적 공감 업소(모텔, 나이트클럽, 가요주점 등) 등은 구단에서는 후원의집 제휴를 하지 않고 있습니다. 이점 양해바랍니다.</p>
      //     <p>2012년도말에 전주유명 모 나이트클럽에서 전북현대를 사랑하는 마음으로 신청하였으나 고민끝에 유선 신청이 반려된적이 있었습니다.</p>
      //     <p>▲ 구단에서는 가능하다면, 고객이 많이 이용하는 업소, 고객이 많이 찾는 요식업 중심의 신청을 받고자 함은 후원의집에서 구단 또한 구단 홍보를 지원받기 위함입니다.</p>
      //     <br>
      //     <p>2. 두번째, 전북현대를 사랑하는 마음이 꼭 함께 해주셨으면 합니다.</p>
      //     <p>▲ 요즘 대부분의 업소 경기가 좋지 않다고들 합니다.</p>
      //     <p>그래서 다수의 후원의집 대표님들께서 업소 마케팅에 업소 수익에 도움이 될 수 있을까 해서 후원의집 신청을 정말 많이많이 해주시고 문의주시고 있습니다.</p>
      //     <p>담당자로서 진심으로 감사드리오나 후원의집에 대한 구단의 입장은 분명합니다.</p>
      //     <p>선정된 후원의집이 잘되어야 구단도 잘된다. 입니다.</p>
      //     <p>그래서, 선정된 후원의집이 잘될 수 있도록 구단 역시 후원의집 홍보를 위해 수익지원을 위해 구단도 노력하고 있습니다.</p>
      //     <p>▲ 그런데, 단순히 구단의 지원만 바라고 후원의집을 신청하셨다면 제휴기간 1년이 지나면 후원의집 제휴 연장을 포기하는 업체들이 몇 곳씩 발생하게 됩니다.</p>
      //     <p>이런 업소의 경우 구단에서 봤을 때 어떻게 생각하겠습니까?</p>
      //     <p>구단 후원의집 역시 구단을 사랑하는 마음이 함께 해주셨으면 하는 이유입니다.</p>
      //     <br>
      //     <p>3. 그래서 마지막으로, 후원의집으로서 구단 지원에 적극적인 마음을 가져주셨으면 합니다.</p>
      //     <p>▲ 구단에서 후원의집에 지원사항으로 부탁드리는 부분이 몇가지 있습니다.</p>
      //     <p>첫째, 후원의집용 현수막 및 포스터 부착을 적극적으로 협조해주십사 하는 것입니다.</p>
      //     <p>둘째, 구단 지급용 50프로할인권 배포시 꼭 경기를 관람코자 하시는 분들께만 배포될 수 있도록 협조해주십사 하는 것입니다.</p>
      //     <p>셋째, 구단관련 지원품 등에 대한 관리가 잘되었으면 합니다.</p>
      //     <p>넷째, 마지막으로, 홈경기가 있을 경우 만약 TV중계가 있을경우 업소내 TV 등으로 고객들께 중계가 될 수 있도록 채널을 고정해주시길 바랍니다.</p>
      //     <p>가능하면 전북현대 선수단이 업소내에서 많은 이야기가 나올 수 있도록 후원의집 대표님들의 협조 부탁드립니다.</p>
      //     <br>
      //     <p>이상과 같이 후원의집에 대한 신청자격을 분류해보았습니다.</p>
      //     <p>더욱 많은 업소에서 대표님들과 전북현대가 제휴를 맺고 전라북도 최고의 브랜드로서 전북현대가 우뚝 설수 있도록 대표님들의 협조 부탁드립니다.</p>
      //     <p>감사합니다.</p>`,
      //     active: false
      //   }
      // ]

      http.get('/main/faq')
        .then(({ data }) => {
          // console.log(data.data);
          for(let i = 0; i < data.data.length; i++) {
            this.list.push({
              question: data.data[i].title,
              answer: data.data[i].content,
              active: false
            })
          }
        })
        .catch(err => {
          console.log(err)
        })
    }
  }
}
</script>
