<template>
  <div id="footer">
    <div class="container">
      <div class="top">
        <a href="https://hyundai-motorsfc.com/policy/service" target="_blank" class="terms">이용약관</a>
        <a href="https://hyundai-motorsfc.com/policy/privacy/latest" target="_blank" class="privacy">개인정보처리방침</a>
      </div>
      <div class="info">
        <p>전라북도 전주시 덕진구 기린대로 1055 전주월드컵경기장</p>
        <p><span>개인정보 보호 관리자 관리자 : 장은서</span> <span>TEL : 063-210-5810</span> <span>FAX : 063-273-1762</span></p>
      </div>
      <p class="copy">Copyright ⒞ 2023 HYUNDAI MOTORS HOUSE ALL RIGHT RESERVED.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FooterView'
}
</script>
