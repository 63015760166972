<template>
  <div id="app" :class="{isMain: isMain}">
    <Header />
    <router-view id="content" />
    <Footer />
  </div>
</template>

<script>
import Header from '@/views/Header'
import Footer from '@/views/Footer'

export default {
  name: 'App',
  components: {
    Header,
    Footer
  },
  computed: {
    isMain () {
      return this.$route.name === 'main'
    }
  }
}
</script>
