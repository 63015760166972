<template>
  <div class="signupProcedure">
    <div class="contentsBox">
      <div class="contentsTitle colorGrey2">후원의집 가입절차</div>
      <div class="contentsInner">
        <div class="paragraph">
          <div class="numIndent">
            <span class="num">1</span>
            <p class="bold">년중 가입신청 접수 - 구단 후원의집 홈페이지 내 신청</p>
            <p class="indent">단, 신청에 대한 구단 조치는 년초 1회(1~3월) 한정하여 가입 조치함</p>
          </div>
          <div class="numIndent">
            <span class="num">2</span>
            <p class="bold">후원의집 후원비 납부</p>
          </div>
          <div class="numIndent">
            <span class="num">3</span>
            <p class="bold">후원의집 지원품 지급 / 설치(상반기 내 완료)</p>
          </div>
          <div class="numIndent">
            <span class="num">4</span>
            <p class="bold">후원의집 홈페이지 내 업소 등록 및 직/간접 홍보</p>
            <p class="indent">단, 후원의집 홈페이지 등록은 업소에서 제공하는 사진을 위주로 등재함</p>
          </div>
        </div>
        <div class="colorBox">
          년중 가입신청 접수
          <p class="desc">구단 후원의집 홈페이지 내 신청</p>
        </div>
        <div class="stepArrow"></div>
        <div class="colorBox">
          구단 담당 후원의집 방문 확인
          <p class="desc">업무 제휴서 작성</p>
        </div>
        <div class="stepArrow"></div>
        <div class="colorBox">
          후원의집 후원비 납부
          <p class="desc">전북은행 528-23-0314480<br>입금처 : 전북현대모터스FC(주)</p>
        </div>
        <div class="stepArrow"></div>
        <div class="colorBox">
          후원의집 지원품 지급 / 설치
          <p class="desc">(홈페이지 등재)</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HouseSignupProcedure',
  created () {
    this.$store.commit('setSubTopData', {
      title: '후원의집 가입절차',
      desc: '전북 후원의집 가입절차에 대하여 알려드립니다.'
    })
  }
}
</script>
