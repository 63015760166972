<template>
  <div>
    <div class="container">
      <div class="premiumList">
        <div class="gridTitle">
          금주의 프리미엄 후원의집
          <router-link :to="{name: 'informationAll'}">더보기</router-link>
        </div>
        <div class="gridList">
          <div
            v-for="(item, index) in list"
            :key="index"
            class="item">
            <router-link :to="{ name: 'informationDetail', params: {id: item.house_seq} }">
              <div class="img" :style="item.orgfile && {backgroundImage: `url('https://house.hyundai-motorsfc.com/UpData/introduce/${item.orgfile}')`}">
                <div v-if="!item.orgfile" class="title">{{ item.house_name }}</div>
              </div>
              <div class="title">{{ item.house_name }}</div>
              <!--<div class="desc">{{ item.address }}</div>-->
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import http from '@/api';

export default {
  name: 'PremiumList',
  mounted () {
    this.init()
  },
  data () {
    return {
      list: []
    }
  },
  methods: {
    init () {
      this.houseListCalls();
    },
    houseListCalls() {
      http.get(`/main/house/best`)
        .then(res => {
          let obj = res.data.data;
          this.list = obj;
        });
    }
  }
}
</script>
