<template>
  <div class="housePage">
    <Top />
    <div class="container">
      <router-view class="contents"></router-view>
    </div>
<!--    <PremiumList />-->
  </div>
</template>

<script>
import Top from '@/components/Common/Subtop'
import PremiumList from '@/components/Common/PremiumList'

export default {
  name: 'HouseView',
  components: {
    Top,
    // PremiumList
  }
}
</script>
