<template>
  <div class="listFilter">
    <select v-if="types && types.length" class="input select" v-model="searchType">
      <option
        v-for="(type, index) in types"
        :key="index"
        :value="type.type">
        {{ type.name }}
      </option>
    </select>
    <input type="text" class="input" placeholder="검색어를 입력하세요" v-model="searchWord">
    <button type="button" class="btn" @click="search">검색</button>
  </div>
</template>

<script>
import { isEmpty } from '@/lib/index';

export default {
  name: 'ListFilter',
  props: [
    'types',
    'reset'
  ],
  watch: {
    reset(val) {
      if(val) {
        this.searchWord = '';
      }
    }
  },
  mounted () {
    if (!isEmpty(this.types)) {
      this.searchType = this.types[0].type
    }
  },
  data () {
    return {
      searchType: '',
      searchWord: ''
    }
  },
  methods: {
    validate () {
      if (!isEmpty(this.types) && isEmpty(this.searchType)) {
        alert('검색타입을 선택하세요.')
        return false;
      } else if (isEmpty(this.searchWord)) {
        alert('검색어를 입력하세요.')
        return false;
      }
      return true;
    },
    search () {
      if (this.validate()) {
        this.$emit('onSearch', {
          type: this.searchType,
          word: this.searchWord
        })
      }
    }
  }
}
</script>
