<template>
  <div>
    <div class="contentsTitle colorGrey2">{{ pageTitle }}
      <router-link :to="listInfo" class="goList">목록으로</router-link>
    </div>
    <div v-if="contents">
      <div class="boardView">
        <div class="boardHeader">
          <div class="subject">{{ contents.title }}</div>
          <div v-if="contents.reg_date || contents.read_count" class="info">
            <span v-if="contents.reg_date" class="date">{{ contents.reg_date }}</span>
            <span v-if="contents.read_count" class="view">{{ comma(contents.read_count) }}</span>
          </div>
        </div>
        <div v-html="contents.content" class="boardContent"></div>
      </div>
      <div class="listTable relative">
        <table>
          <tr v-if="prev">
            <td class="num">이전글</td>
            <td class="subject">
              <router-link :to="{name: 'houseNoticeView', params: { id: prev.seq }}">
                {{ prev.title }}
              </router-link>
            </td>
            <td class="date">{{ prev.reg_date }}</td>
            <td class="view">{{ comma(prev.read_count) }}</td>
          </tr>
          <tr v-else>
            <td colspan="4" class="noData">이전글이 없습니다.</td>
          </tr>
          <tr v-if="next">
            <td class="num">다음글</td>
            <td class="subject">
              <router-link :to="{name: 'houseNoticeView', params: { id: next.seq }}">
                {{ next.title }}
              </router-link>
            </td>
            <td class="date">{{ next.reg_date }}</td>
            <td class="view">{{ comma(next.read_count) }}</td>
          </tr>
          <tr v-else>
            <td colspan="4" class="noData">다음글이 없습니다.</td>
          </tr>
        </table>
      </div>
      <div class="tableBtns right">
        <router-link :to="listInfo" class="btn">목록으로</router-link>
      </div>
    </div>
    <div v-else>
      <div class="loader"></div>
    </div>
  </div>
</template>

<script>
import regExp from '@/common/regExp'
import {htmlConverter, isEmpty} from '@/lib';
import http from '@/api';
import dayjs from 'dayjs';

export default {
  name: 'NoticeView',
  computed: {
    pageTitle () {
      return this.$store.state.subTop.title || 'Title'
    }
  },
  mounted () {
    this.init()
  },
  data () {
    return {
      listInfo: {
        name: 'houseNotice'
      },
      contents: null,
      prev: null,
      next: null
    }
  },
  watch: {
    $route(to, form) {
      this.$router.go(0);
    }
  },
  methods: {
    init () {
      const params = this.$route.params.id;
      this.detailView(params);
    },
    comma (str) {
      return regExp.comma(`${str}`)
    },
    detailView(params) {
      let url = `/main/notice/${params}`;

      http.get(url)
        .then(res => {
          let obj = res.data.data;

          obj.detail.reg_date = dayjs(obj.detail.reg_date).format("YYYY-MM-DD");
          obj.detail.read_count = this.comma(obj.detail.read_count);
          obj.detail.content = htmlConverter(obj.detail.content);

          if(!isEmpty(obj.after)) {
            obj.after.reg_date = dayjs(obj.after.reg_date).format("YYYY-MM-DD");
          }

          if(!isEmpty(obj.before)) {
            obj.before.reg_date = dayjs(obj.before.reg_date).format("YYYY-MM-DD");
          }

          this.contents = obj.detail;
          this.next = obj.after;
          this.prev = obj.before;
        });
    }
  }
}
</script>
