<template>
  <div>
    <div v-if="!isView">
      <div class="contentsTitle colorGrey2">후원의집 소개
        <div class="listTotal">총 <span class="bold colorPrimary2">{{noticeCount}}</span>개의 후원의집 소개 게시물이 있습니다</div>
      </div>
      <div v-if="list && list.length" class="newsList">
        <div
          v-for="(item, index) in list"
          :key="index"
          class="item"
          :class="{hasImg: item.t_image}">
          <router-link :to="{name: 'talkNewsView', params: {id: item.seq}}">
            <div class="text">
              <div class="title">{{ item.title }}</div>
              <div v-if="item.content" class="desc" v-html="item.content"></div>
              <!--
              <div class="desc">
                <span v-html="item.content"></span>
              </div>
              -->
              <div class="info">
                <span class="date">{{ item.reg_date }}</span>
                <span class="view">{{ comma(item.read_count) }}</span>
              </div>
            </div>
            <div v-if="item.t_image" class="img" :style="item.t_image && {backgroundImage: `url('https://house.hyundai-motorsfc.com/UpData/board/${item.t_image}')`}"></div>
          </router-link>
        </div>
      </div>
      <div v-else class="noListData">데이터가 없습니다</div>
      <Pagination v-bind:total="noticeCount" v-bind:pageDisplayCount="pageCount" @onChange="pageChange" />
      <ListFilter
        :types="searchTypes"
        @onSearch="searchList" />
    </div>
    <router-view v-else></router-view>
  </div>
</template>

<script>
import regExp from '@/common/regExp'
import Pagination from '@/components/Common/Pagination'
import ListFilter from '@/components/Common/ListFilter'
import {isEmpty, htmlConverter, htmlDelete} from '@/lib';
import http from '@/api';
import dayjs from 'dayjs';

export default {
  name: 'TalkNews',
  components: {
    Pagination,
    ListFilter
  },
  computed: {
    isView () {
      return this.$route.params.id
    }
  },
  created () {
    this.$store.commit('setSubTopData', {
      title: '후원의집 소개',
      desc: '팬 여러분께 자신있게 후원의집을 소개합니다'
    })

    this.init()
  },
  mounted () {
  },
  data () {
    return {
      notice_gbn: 'I',
      pageNum: 1,
      noticeCount: 0,
      pageCount: 0,
      list: [],
      searchTypes: [
        {
          type: 'subject',
          name: '제목'
        }
      ],
      searchpart: '',
      searchtext: ''
    }
  },
  methods: {
    init () {
      /*this.list = [
        {
          id: 2,
          subject: '"화력폭발" 전북, 울산 잡고 K리그 1 우승도전 이어간다',
          desc: '올 시즌 마지막 현대가 더비다. 잔여 경기는 4경기. 전북은 필승을 다짐하고 있다. 승리를 거두면 승점 차는 2로 좁혀지고 남은 3경기에서 전북이 역전 우승을 할 수도 있다. 전북도 반드시 승리해야만 사실상 마지막 역전 우승을 기대할 수 있다.',
          img: 'https://jbhd-upload-file.s3.ap-northeast-2.amazonaws.com/uploadFile/SoccerUpload/news/2023/08/image_64d051f8d2b5b2.25048487.jpg',
          date: '2022-07-09',
          view: 10415
        }
      ]*/

      this.noticeListCalls();
    },
    comma (str) {
      return regExp.comma(`${str}`)
    },
    pageChange (page) {
      this.pageNum = page;
      this.noticeListCalls();
    },
    searchList (data) {
      this.searchpart = data.type
      this.searchtext = data.word;

      this.noticeListCalls('search');
    },
    noticeListCalls(key) {
      let url = `/main/notice?notice_gbn=${this.notice_gbn}&page=${this.pageNum}`;

      if (!isEmpty(this.searchtext)){
        url += `&searchpart=${this.searchpart}&searchtext=${this.searchtext}`;
      }

      http.get(url)
        .then(res => {
          let obj = res.data.data;
          this.noticeCount = res.data.total;
          this.pageCount = res.data.per_page;

          Object.entries(obj).forEach(([, value]) => {
            // value.t_image = '/UpData/introduce/' + value.t_image;

            value.content = htmlConverter(value.content);
            value.content = htmlDelete(value.content);
            value.reg_date = dayjs(value.reg_date).format("YYYY-MM-DD");
          });

          this.list = obj;
        });
    }
  }
}
</script>
