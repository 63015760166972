export default {
  name: 'regExp',
  htmlCommentSingle: /<!--(.*?)-->/gm,
  naturalNumber: /^[1-9]\d*$/,
  expEmail: /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/,
  expPhone: /^\d{2,3}-\d{3,4}-\d{4}$/,
  expPassword: /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{9,20}$/,
  webfont: /(woff|woff2)$/,
  image: /(png|jpeg|svg|jpg|gif)$/,
  numberOnly: function (num) {
    return num.toString().replace(/[^0123456789]/g, '')
  },
  phoneFormatter: function (num) {
    const reg = /(\d{3})(\d{3,4})(\d{4})/
    return num.replaceAll(/[^0-9]/g, '').replace(reg, '$1-$2-$3')
  },
  priceOnly: function (num) {
    return num.toString().match(/\d*/g).join('')
      .match(/(\d{0,3})(\d{0,4})(\d{0,4})/).slice(1).join('-')
      .replace(/-*$/g, '')
  },
  comma: function (num) {
    const reg = /^([-+]?\d+)(\d{3})(\.\d+)?/
    let string = '' + (num != null ? num : '')
    string = string.replace(/[^-+.\d]/g, '')
    while (reg.test(string)) string = string.replace(reg, '$1' + ',' + '$2' + '$3')
    return string
  },
  uncomma: function (str) {
    str = String(str)
    return str.replace(/[^-.\d]/g, '')
  },
  replaceAllEmpty: function (str) {
    return str.replace(/(\s*)/g, '').toLowerCase()
  },
  camelize: function (str) {
    return str ? str.replace(/([-_][a-z])/g,
      (group) => group.toUpperCase()
        .replace('-', '')
        .replace('_', '')
    ) : ''
  }
}
