<template>
  <div class="houseIntro">
    <div class="contentsBox">
      <div class="contentsTitle colorGrey2">후원의집이란?</div>
      <div class="contentsInner"><span class="bold colorPrimary2">'후원의집'</span>이란 전북현대모터스FC를 사랑하는 공식 업체(업소)를 의미하며, 축구 붐 조성 및 팬서비스 확대를 위해 자발적으로 경기 일정, 구단 소식 등 <span class="bold colorPrimary2">전북현대모터스를 대신하여 홍보를 적극 지원</span>하며, <span class="bold colorPrimary2">상호 마케팅 사항을 직, 간접적으로 팬과 고객에게 실천하고 후원</span>하는 업체를 말합니다</div>
    </div>
    <div class="contentsBox">
      <div class="contentsTitle colorGrey2">구단과 '후원의집'의 역할</div>
      <div class="contentsInner">
        <div class="infoGraphic">
          <div class="round">후원의집</div>
          <div class="round">WIN-WIN</div>
          <div class="round">구단</div>
        </div>
        <p class="paragraph">프로축구 구단인 전북현대모터스와 <span class="bold colorPrimary2">'후원의집'</span>은 스포츠 경기(축구)를 통하여 공동 발전을 지향하고 지역 경제 발전에 기여합니다. <span class="bold colorPrimary2">'구단'</span>은 '구단'의 팬들로 하여금 '후원의집'을 홍보하여 이용을 권장토록 하여 '후원의집' 번영을 적극 지원하며, '후원의집'의 매출 신장과 인지도 제고에 기여할 수 있도록 노력하며, <span class="bold colorPrimary2">'후원의집'</span>은 '전북구단'의 홍보 및 기타 업무 관련하여 구단을 대신하여 적극적으로 협조, 지원하여 <span class="bold colorPrimary2">상호간 Win-Win 할 수 있는 역할을 수행합니다.</span></p>
        <p class="contentsInnerTitle colorGrey2">구단 후원의집 로고</p>
        <div class="graphicBox"><img src="@/assets/img/logo_house.png" alt=""></div>
        <p>전북현대모터스 후원의집으로써 표시 및 구단 핵심 이미지인 웅비하는 봉황을 배경으로 하여 후원의집 번창하길 바라는 염원을 표출한다.</p>
      </div>
    </div>
    <div class="contentsBox">
      <div class="contentsTitle colorGrey2">'후원의집' 가입조건</div>
      <div class="contentsInner">
        <div class="colorBox">
          <div class="unit">전북현대모터스에프씨를 사랑하는 마음</div>
          <div class="plus">+</div>
          <div class="unit">구단을 충분히 홍보해 줄 수 있는 조건 제공 업소</div>
        </div>
        <p class="paragraph">후원의집은 전라북도 지역 뿐만이 아니라 전국 어디서나 전북을 좋아하시고 사랑하신다면 언제든지 가능합니다.</p>
        <p class="contentsInnerTitle colorGrey2">가입 상세 조건</p>
        <ul class="paragraph">
          <li>① 후원비 : 최소 후원비 20만원(VAT 포함)</li>
          <li>② 추가 후원은 업체 자율이며 그에 따른 추가 마케팅 활동이 있을 수 있습니다.</li>
          <li>※ 구단 홈경기 포스터를 업소 내부에 부착해줄 수 있는 업소</li>
        </ul>
        <p class="contentsInnerTitle colorGrey2">후원의집 가입 문의</p>
        <p>후원의집 담당 (장은서 책임 T : 210-5810)</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HouseIntro',
  created () {
    this.$store.commit('setSubTopData', {
      title: '후원의집이란?',
      desc: '전북현대모터스FC와 10만 홈팬들이 함께 할 후원의집을 소개합니다.'
    })
  }
}
</script>
