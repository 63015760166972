<template>
  <div class="pagination">
    <button type="button" class="first" @click="setPage(1)"></button>
    <button type="button" class="prev" @click="onePage('prev')"></button>
    <button
      v-for="(item, index) in countInPages"
      :key="index"
      type="button"
      class="page"
      :class="{active: (startPage-1) + item === page}"
      @click.stop="setPage((startPage-1) + item)">
      {{(startPage-1) + item}}
    </button>
    <button v-if="buttonDisplay" type="button" class="next" @click="onePage('next')"></button>
    <button v-if="buttonDisplay" type="button" class="last" @click="setPage(paginationCount)"></button>
  </div>
</template>

<script>
import {computed} from 'vue';
import {round} from '@/lib';

export default {
  name: 'Pagination',
  props: [
    'total',
    'current',
    'pageDisplayCount' // 보여질 페이지 수
  ],
  data () {
    let page = this.current || 1;
    return {
      page,
      totalPageCount: this.total || 0, // 총 페이지 수
      paginationCount: 0,
      buttonCount: 0,
      defaultPages: 10,
      buttonDisplay: false
    }
  },
  watch: {
    total: {
      handler: async function () {
        this.totalPageCount = this.total;
        this.paginationCount = round(Math.ceil((this.totalPageCount/this.pageDisplayCount)));
        this.page = 1;
        this.buttonDisplay = true;

        /*const buttonDisplay = computed(() => this.paginationCount > 10);
        this.buttonDisplay = buttonDisplay.value;*/
      }
    },
    page: {
      handler: async function () {
        this.buttonDisplay = this.page !== this.paginationCount;
      }
    }
  },
  computed: {
    startPage() {
      let value = (Math.floor((this.page-1) / this.defaultPages) * this.defaultPages) + 1;
      return value
    },
    totalPage () {
      const count = this.pageDisplayCount ? this.pageDisplayCount : 10;
      return Math.floor(this.totalPageCount / count) + ((this.totalPageCount % count) == 0 ? 0 : 1);
    },
    countInPages() {
      let currPages;
      currPages = this.totalPage - (this.startPage-1);

      if (currPages < this.defaultPages) {
        return currPages;
      } else {
        return this.defaultPages;
      }
    }
  },
  created() {
  },
  methods: {
    setPage (page) {
      this.page = page;
      this.$emit('onChange', page);
    },
    onePage(key) {
      if(key.includes('prev') && this.page > 1) {
        this.page = this.page-1;
      }

      if (key.includes('next') && this.page < this.paginationCount) {
        this.page = this.page+1;
      }

      this.$emit('onChange', this.page);
    }
  }
}
</script>
