<template>
  <div class="banner">
    <div class="container">
      <div class="bannerBox">
        <div class="match">
          <div class="bannerTitle">다음 경기</div>
          <div v-if="game">
            <div class="game">
              <div class="name">{{ game.home_team_name }}</div>
              <div class="emblem"><img :src="getEmblem(game.home_team)" alt=""></div>
              <div class="vs">VS</div>
              <div class="emblem"><img :src="getEmblem(game.away_team)" alt=""></div>
              <div class="name">{{ game.away_team_name }}</div>
            </div>
            <div class="info">
              <div class="stadium">{{ game.field_name }}</div>
              <div class="date">{{ game.game_date }}({{game.yoil}}) {{ game.game_time }}</div>
            </div>
            <div class="btns">
              <a href="https://hyundai-motorsfc.com/ticket" target="_blank" class="btn">입장권 예매하기</a>
            </div>
          </div>
          <div v-else class="noData">다음 경기 일정이 없습니다.</div>
        </div>
        <div class="hyundaicar" :class="{autoplay: !swiperPause}">
          <div class="bannerTitle">현대차 지점</div>
          <button type="button" class="btnPlay" @click.stop="toggleAutoplay"></button>
          <swiper
            ref="bannerSwiper"
            class="swiper"
            :options="swiperOption">
            <swiper-slide
              v-for="(item, index) in swiperItems"
              :key="index"
              class="slide">
              <router-link :to="{name: 'informationDetail', params: {id: item.tr_seq}}" class="slideInner">
                <div class="img" :style="item.orgfile && {backgroundImage: `url('https://house.hyundai-motorsfc.com/UpData/introduce/${item.orgfile}')`}"></div>
                <div class="info">
                  <div class="name">{{ item.company }}</div>
                  <div class="tel">{{ item.tel }}</div>
                  <div class="address">{{ item.newaddr }}</div>
                </div>
              </router-link>
            </swiper-slide>
          </swiper>
          <div class="btns">
            <router-link :to="{name: 'informationType', params: {type: 'hyundaicar'}}" class="btn">전북 현대차 지점 찾기</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import http from '@/api';
import {isEmpty, telHyphen} from '@/lib';
import dayjs from 'dayjs';

export default {
  name: 'MainBanner',
  components: {
    Swiper,
    SwiperSlide
  },
  mounted () {
    this.init()
  },
  data () {
    return {
      swiperItems: [],
      swiperPause: false,
      swiperOption: {
        slidesPerView: 1,
        loop: true,
        autoplay: {
          delay: 3000,
          disableOnInteraction: false
        }
      },
      game: null
    }
  },
  methods: {
    init () {
      this.nextMatch();
      this.houseListCalls();
    },
    getEmblem (id) {
      return `https://s3.ap-northeast-2.amazonaws.com/crm.thesports.io/img/common/emblem/${id}.png`
    },
    toggleAutoplay () {
      const swiper = this.$refs.bannerSwiper
      if (swiper) {
        if (this.swiperPause) {
          swiper.$swiper.autoplay.run()
        } else {
          swiper.$swiper.autoplay.pause()
        }
        this.swiperPause = !this.swiperPause
      }
    },
    houseListCalls() {
      let url = `/main/house?page=1&searchpart=company_gbn&searchtext=C12`;

      http.get(url)
        .then(res => {
          let obj = res.data.data;

          Object.entries(obj).forEach(([, value]) => {
            if(!isEmpty(value.tel) && !value.tel.includes('-')) {
              value.tel = telHyphen(value.tel)
            }
          });

          this.swiperItems = obj;
        });
    },
    nextMatch() {
      let url = `https://api.jbfc.kr/main/next_match`;

      http.get(url)
        .then(res => {
          let obj = res.data.data;

          Object.entries(obj).forEach(([, value]) => {
            value.game_time = value.game_time.substring(0, value.game_time.length-3);
          });

          this.game = obj[0];
        });
    }
  }
}
</script>
