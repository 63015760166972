<template>
  <div class="partnerMarketing">
    <div class="contentsBox">
      <div class="contentsTitle colorGrey2">후원의집 마케팅이란?</div>
      <div class="contentsInner">
        <div class="paragraph">
          <div class="numIndent">
            <span class="num">1</span>
            <p class="bold"><span class="colorPrimary">후원의집 지급 인쇄물</span> 또는 <span class="colorPrimary">구단 후원의집 홈페이지</span> 등에 <span class="colorPrimary">업소 광고</span> 실시</p>
          </div>
          <div class="numIndent">
            <span class="num">2</span>
            <p class="bold">후원의집과 구단 제휴 사항에 의한 <span class="colorPrimary">팬서비스 확대</span></p>
          </div>
        </div>
        <div class="graphicBox">
          <img src="@/assets/img/img_marketing_1.png" alt="">
        </div>
        <div class="paragraph">
          <div class="contentsInnerTitle colorGrey2">포스터 하단 광고</div>
          <ul>
            <li>&bull; 내역 : 후원의집과 제휴시 포스터 부착 의무사항으로써 저렴한 광고료에 의한 광고 효과우수</li>
            <li>&bull; 광고위치 : 포스터 하단 지면 광고 삽입(크기 : 가로 400mm * 세로 100mm)</li>
            <li>&bull; 부착기간 : 최소 1개월이상</li>
            <li>&bull; 제작수량 : 최소 750장 이상</li>
            <li>&bull; 광고료 : 담당자 문의</li>
          </ul>
        </div>
        <div class="graphicBox">
          <img src="@/assets/img/img_marketing_2.png" alt="">
        </div>
        <div class="paragraph">
          <div class="contentsInnerTitle colorGrey2">홈페이지 내 배너광고</div>
          <ul>
            <li>&bull; 내역 : 구단 후원의집 내 배너광고</li>
            <li>&bull; 광고위치 : 홈페이지 메인 상단 및 좌우 하단 배너 광고<br>
              &nbsp;&nbsp;&nbsp;- 상단광고 : 최대 4개업소 롤링형식 (분당 15초)<br>
              &nbsp;&nbsp;&nbsp;- 노출시점 : 상시 노출<br>
              &nbsp;&nbsp;&nbsp;- 좌우광고 : 플래쉬 노출</li>
            <li>&bull; 광고기간 : 년간 계약</li>
            <li>&bull; 광고료 : 담당자 문의</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="contentsBox">
      <div class="contentsTitle colorGrey2">후원의집 마케팅 신청</div>
      <div class="contentsInner">
        <div class="formTable">
          <table>
            <tr>
              <th>신청자</th>
              <td>
                <input type="text" class="input" v-model="applyData.applicant" placeholder="신청자를 입력하세요">
              </td>
            </tr>
            <tr>
              <th>핸드폰</th>
              <td>
                <input-phone v-model="applyData.phone" :label="'핸드폰번호를 입력하세요'" />
              </td>
            </tr>
            <tr>
              <th>광고영역</th>
              <td>
                <select class="input select" v-model="applyData.ad">
                  <option value="" selected disabled hidden>선택하기</option>
                  <option value="포스터 하단 광고">포스터 하단 광고</option>
                  <option value="할인권 티켓 광고">할인권 티켓 광고</option>
                  <option value="홈페이지 배너 광고">홈페이지 배너 광고</option>
                </select>
              </td>
            </tr>
          </table>
        </div>
        <div class="btnViewmore">
          <!--<router-link :to="{name: 'informationMap'}" class="btn viewmore">신청하기</router-link>-->
          <button type="button" class="btn viewmore" @click="toAply()">신청하기</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {isEmpty} from '@/lib';
import InputPhone from '@/components/Common/InputPhone.vue';
import http from '@/api';

export default {
  name: 'PartnerMarketing',
  components: {
    InputPhone
  },
  created () {
    this.$store.commit('setSubTopData', {
      title: '후원의집 마케팅 내역',
      desc: '후원의집과 관련 구단이 함께할 수 있는 마케팅 내역을 소개하오니 많은 제휴검토 바랍니다.'
    })
  },
  data() {
    return {
      applyData: {
        applicant: '',
        phone: '',
        ad: ''
      }
    }
  },
  methods: {
    toAply() {
      // 필수값 체크
      if (!this.isMstValid()) {
        return;
      }

      this.applyAdd();
    },
    isMstValid() {
      if (isEmpty(this.applyData.applicant)) {
        alert('신청자는 입력 필수입니다.');
        return false;
      }

      if (isEmpty(this.applyData.phone)) {
        alert('핸드폰번호는 입력 필수입니다.');
        return false;
      }

      if(!this.telNumChk(this.applyData.phone)){
        alert('핸드폰번호를 올바르게 작성하세요.')
        return false;
      }

      if (isEmpty(this.applyData.ad)) {
        alert('광고영역은 선택 필수입니다.')
        return false;
      }

      return true;
    },
    applyAdd() {
      if(!confirm('신청하시겠습니까?')) {
        return false;
      }

      const saveResult = {
        applicant: this.applyData.applicant,
        phone: this.applyData.phone,
        ad: this.applyData.ad
      };

      http.post(`/apply/marketing`, saveResult)
        .then(res => {
          alert('신청되었습니다.');
          this.$router.go(0);
        }).catch((e) => {
        // console.log(e);
      });
    },
    telNumChk(tel) {
      let val = tel.replace(/[^\d]/g, "");

      if (val[0] != 0) {
        if (val.length < 8) {
          return false;
        }
      }else if (val[1] == 2) {
        if (val.length < 10) {
          return false;
        }
      }else {
        if (val.length < 11) {
          return false;
        }
      }
      return true;
    }
  }
}
</script>

<script setup>
</script>
