import axios from "axios";

const baseURL = "https://house-api.jbfc.kr/";

const instance = axios.create({
    baseURL: baseURL,
    headers: { "content-type": "application/json" },
    timeout: 30000,
    withCredentials: true,
    crossDomain: true,
    credentials: "include"
});

export default instance;