<template>
  <div class="visual">
    <swiper
      ref="visualSwiper"
      class="swiper"
      :options="swiperOption">
      <swiper-slide
        v-for="(item, index) in list"
        :key="index"
        class="slide">
        <div class="slideInner" :style="item.img && {backgroundImage: `url('${item.img}')`}">
          <a :href="item.link || '#'" class="slideLink">
            <div class="container">
              <div class="title">{{ item.title }}</div>
              <div class="desc">{{ item.content }}</div>
            </div>
          </a>
        </div>
      </swiper-slide>
      <div class="swiper-pagination" slot="pagination"></div>
    </swiper>
    <button type="button" class="btnPause" :class="{autoplay: !swiperPause}" @click.stop="toggleAutoplay"></button>
    <span class="scrollDown"></span>
    <div class="link">
      <router-link to="/information/map" class="item">후원의집 찾기</router-link>
      <router-link :to="{name: 'houseSignup'}">후원의집 신청하기</router-link>
    </div>
  </div>
</template>

<script>
import http from '@/api/index'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

export default {
  name: 'MainVisual',
  components: {
    Swiper,
    SwiperSlide
  },
  mounted () {
    this.init()
  },
  data () {
    return {
      swiperOption: {
        slidesPerView: 1,
        loop: false,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        autoplay: {
          delay: 3000,
          disableOnInteraction: false
        }
      },
      swiperPause: false,
      list: []
    }
  },
  methods: {
    async init () {
      const swiper = this.$refs.visualSwiper
      if (swiper) {
        swiper.$swiper.activeIndex = 1
      }

      http.get(`/main/slide`)
        .then(res => {
          this.list = res.data.data;
        });
    },
    toggleAutoplay () {
      const option = this.swiperOption
      const swiper = this.$refs.visualSwiper
      if (swiper) {
        if (this.swiperPause) {
          swiper.$swiper.autoplay.run()
        } else {
          swiper.$swiper.autoplay.pause()
        }
        this.swiperPause = !this.swiperPause
      }
    }
  }
}
</script>
