<template>
  <div class="subTop" :class="{ isHouseDetail: isHouseDetail }" :style="bgImg && {backgroundImage: `url('https://house.hyundai-motorsfc.com/UpData/introduce/${bgImg}')`}">
    <div class="container">
      <div v-if="tags && tags.length" class="tags">
        <span
          v-for="(item, index) in tags"
          :key="index"
          @click="chagesCode(item)"
          class="tag">
          #{{ item.gbn_txt }}
        </span>
      </div>
      <div class="title">{{ title }}</div>
      <div v-if="desc" class="desc">{{ desc }}</div>
    </div>
  </div>
</template>

<script>
import {companyGbnType} from '@/lib';

export default {
  name: 'SubTop',
  computed: {
    isHouseDetail () {
      return this.$route.name === 'informationDetail'
    },
    title () {
      return this.$store.state.subTop.title
    },
    desc () {
      return this.$store.state.subTop.desc
    },
    tags () {
      return this.$store.state.subTop.tags
    },
    bgImg () {
      return this.$store.state.subTop.bgImg
    }
  },
  methods: {
    chagesCode(item) {
      if(item.type.includes('type')) {
        this.changeType(item.gbn_code);
      }else {
        this.changeArea(item.gbn_code);
      }
    },
    changeType (code) {
      code = companyGbnType(code);
      this.$router.push({
        path: 'type/:type',
        name: 'informationType',
        params: {
          type: code
        }
      })
    },
    changeArea (code) {
      this.$router.push({
        path: 'area/:area',
        name: 'informationArea',
        params: {
          area: code
        }
      })
    }
  }
}
</script>

<style scoped>
.subTop .tags .tag {
  cursor: pointer;
}
</style>
