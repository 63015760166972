<template>
  <div class="news">
    <div class="container">
      <div class="sectionTitle">
        <h2 class="title">후원의집 소식</h2>
        <p class="desc">후원의집 관련 구단의 소식을 알려드립니다.</p>
      </div>
      <div class="tabBtn">
        <button
          v-for="(item, index) in types"
          :key="index"
          type="button"
          class="btn"
          :class="{active: item.active}"
          @click.stop="getNews(item.id)">
          {{ item.name }}
        </button>
      </div>
      <div class="gridList">
        <div
          v-for="(item, index) in list"
          :key="index"
          class="item">
          <router-link :to="getLink(item)">
            <div class="title">{{ item.title }}</div>
            <!--<div class="desc">{{ item.content }}</div>-->
            <div class="date">{{ item.reg_date }}</div>
            <div class="img">
              <div v-if="!item.t_image" class="text">
                <div class="title">{{ item.title }}</div>
                <div class="date">{{ item.reg_date }}</div>
              </div>
              <img v-else :src="'https://house.hyundai-motorsfc.com/UpData/introduce/'+item.t_image" alt="" @error="replaceImg">
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {htmlConverter} from '@/lib';
import http from '@/api';
import dayjs from 'dayjs';

export default {
  name: 'MainNews',
  mounted () {
    this.init()
  },
  data () {
    return {
      list: [],
      types: [
        {
          id: 'N',
          name: '공지사항',
          route: 'houseNotice',
          active: true
        },
        {
          id: 'E',
          name: '홍보 / 제휴',
          route: 'partnerPromotion',
          active: false
        },
        {
          id: 'I',
          name: '소개',
          route: 'talkNews',
          active: false
        },
        {
          id: 'O',
          name: '자유게시판',
          route: 'talkBoard',
          active: false
        }
      ]
    }
  },
  methods: {
    init () {
      this.getNews('N')
    },
    getLink (item) {
      const type = item && item.notice_gbn
      const routeName = type && this.types.find(x => x.id === type) && this.types.find(x => x.id === type).route
      const id = item.seq;
      return routeName && id
        ? { name: `${routeName}View`, params: { id: id } }
        : '/'
    },
    getNews (type) {
      this.types.forEach((obj, ) => {
        if(obj.id.includes(type)) {
          obj.active = true;
        }else {
          obj.active = false;
        }
      });
      this.noticeListCalls(type);
    },
    noticeListCalls(key) {
      let url = `/main/notice?notice_gbn=${key}&page=1`;

      http.get(url)
        .then(res => {
          let obj = res.data.data;
          let arr = [];
          Object.entries(obj).forEach(([key, value]) => {
            value.content = htmlConverter(value.content);
            value.reg_date = dayjs(value.reg_date).format("YYYY-MM-DD");

            if(key < 4){
              arr.push(value);
            }
          });

          this.list = arr;
        });
    },
    replaceImg(e) {
      e.target.src = require('@/assets/img/bg_grid_item.png') // 디폴트
    }
  }
}
</script>
