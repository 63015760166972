import Vue from 'vue'
import VueRouter from 'vue-router'
import Main from '../views/Main.vue'
import House from '../views/House.vue'
import HouseIntro from '../components/House/Intro.vue'
import HouseBenefit from '../components/House/Benefit.vue'
import HouseSignupProcedure from '../components/House/SignupProcedure.vue'
import HouseSignup from '../components/House/Signup.vue'
import HouseNotice from '../components/House/Notice.vue'
import HouseNoticeView from '../components/House/NoticeView.vue'
import HouseFaq from '../components/House/Faq.vue'
import Information from '../views/Information.vue'
import InformationMap from '../components/Information/Map.vue'
import InformationType from '../components/Information/Type.vue'
import InformationArea from '../components/Information/Area.vue'
import InformationAll from '../components/Information/All.vue'
import InformationDetail from '../components/Information/Detail.vue'
import Talk from '../views/Talk.vue'
import TalkNews from '../components/Talk/News.vue'
import TalkNewsView from '../components/Talk/NewsView.vue'
import TalkBoard from '../components/Talk/Board.vue'
import TalkBoardView from '../components/Talk/BoardView.vue'
import Partner from '../views/Partner.vue'
import PartnerMarketing from '../components/Partner/Marketing.vue'
import PartnerPromotion from '../components/Partner/Promotion.vue'
import PartnerPromotionView from '../components/Partner/PromotionView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'main',
    component: Main
  },
  {
    path: '/house',
    name: 'house',
    component: House,
    meta: {
      title: '후원의집이란?', // 메뉴바에 보일 타이틀
      enTitle: 'Green House?' // 영문으로 변경시 사용할 타이틀 (없으면 title이 기본값)
    },
    redirect: {
      name: 'houseIntro'
    },
    children: [
      {
        path: 'intro',
        name: 'houseIntro', // chilren router name은 parent router name과 합쳐 camelCase로 변경됨 -> houseIntro
        component: HouseIntro,
        meta: {
          title: '후원의집이란',
          enTitle: 'About'
        }
      },
      {
        path: 'benefit',
        name: 'houseBenefit',
        component: HouseBenefit,
        meta: {
          title: '혜택',
          enTitle: 'Benefit'
        }
      },
      {
        path: 'procedure',
        name: 'houseSignupProcedure',
        component: HouseSignupProcedure,
        meta: {
          title: '가입절차',
          enTitle: 'Signup Procedure'
        }
      },
      {
        path: 'signup',
        name: 'houseSignup',
        component: HouseSignup,
        meta: {
          title: '가입 신청하기',
          enTitle: 'Signup'
        }
      },
      {
        path: 'notice',
        name: 'houseNotice',
        component: HouseNotice,
        meta: {
          title: '공지사항',
          enTitle: 'Notice'
        },
        children: [
          {
            path: ':id',
            name: 'houseNoticeView',
            component: HouseNoticeView,
            params: {
              id: 1
            }
          }
        ]
      },
      {
        path: 'faq',
        name: 'houseFaq',
        component: HouseFaq,
        meta: {
          title: 'FAQ'
        }
      },
    ]
  },
  {
    path: '/information',
    name: 'information',
    component: Information,
    meta: {
      title: '후원의집 현황'
    },
    redirect: {
      name: 'informationMap'
    },
    children: [
      { 
        path: 'map',
        name: 'informationMap',
        component: InformationMap,
        meta: {
          title: '지도로 찾기'
        }
      },
      {
        path: 'type/:type',
        name: 'informationType',
        component: InformationType,
        meta: {
          title: '업종별 분류',
          noPremium: true
        },
        params: {
          type: 'all'
        }
      },
      {
        path: 'area/:area',
        name: 'informationArea',
        component: InformationArea,
        meta: {
          title: '지역별 분류',
          noPremium: true
        },
        params: {
          area: 'all'
        }
      },
      {
        path: 'all',
        name: 'informationAll',
        component: InformationAll,
        meta: {
          title: '전체현황',
          noPremium: true
        }
      },
      {
        path: 'detail/:id',
        name: 'informationDetail',
        component: InformationDetail
      }
    ]
  },
  {
    path: '/talk',
    name: 'talk',
    component: Talk,
    meta: {
      title: '토크 & 토크',
      enTitle: 'Talk & Talk'
    },
    redirect: {
      name: 'talkNews'
    },
    children: [
      {
        path: 'news',
        name: 'talkNews',
        component: TalkNews,
        meta: {
          title: '후원의집 소개',
          noPremium: true
        },
        children: [
          {
            path: ':id',
            name: 'talkNewsView',
            component: TalkNewsView,
            params: {
              id: 1
            },
            meta: {
              noPremium: true
            }
          }
        ]
      },
      {
        path: 'board',
        name: 'talkBoard',
        component: TalkBoard,
        meta: {
          title: '자유게시판',
          noPremium: true
        },
        children: [
          {
            path: ':id',
            name: 'talkBoardView',
            component: TalkBoardView,
            params: {
              id: 1
            },
            meta: {
              noPremium: true
            }
          }
        ]
      }
    ]
  },
  {
    path: '/partner',
    name: 'partner',
    component: Partner,
    meta: {
      title: '후원의집 제휴'
    },
    redirect: {
      name: 'partnerMarketing'
    },
    children: [
      {
        path: 'marketing',
        name: 'partnerMarketing',
        component: PartnerMarketing,
        meta: {
          title: '마케팅 내역'
        }
      },
      {
        path: 'promotion',
        name: 'partnerPromotion',
        component: PartnerPromotion,
        meta: {
          title: '홍보 / 제휴',
          noPremium: true
        },
        children: [
          {
            path: ':id',
            name: 'partnerPromotionView',
            component: PartnerPromotionView,
            params: {
              id: 1
            },
            meta: {
              noPremium: true
            }
          }
        ]
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior (to, from, savedPosition) {
    if (to.name !== from.name) {
      return { x: 0, y: 0 }
    }
  },
  routes
})

export default router
