<template>
  <div class="mainPage">
    <Visual />
    <News class="section" />
<!--    <Premium class="section" />-->
    <Banner class="section" />
    <House class="section" />
  </div>
</template>

<script>
import Visual from '@/components/Main/Visual'
import News from '@/components/Main/News'
import Premium from '@/components/Main/Premium'
import Banner from '@/components/Main/Banner'
import House from '@/components/Main/House'

export default {
  name: 'MainView',
  components: {
    Visual,
    News,
    // Premium,
    Banner,
    House
  }
}
</script>
