import Vue from 'vue'
import Vuex from 'vuex'
import router from '@/router'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    langs: [
      'KOR',
      'ENG'
    ],
    lang: 'KOR',
    subTop: {
      title: 'Top title',
      desc: 'Top description',
      tags: null
    }
  },
  mutations: {
    setLang (state, lang) {
      state.lang = lang
    },
    setSubTopData (state, data) {
      state.subTop = data
    }
  },
  actions: {
    getHouseList ({state}) {
      const list = [
        {
          id: '1',
          type: 'life',
          name: '양가네감자탕',
          typeName: '생활',
          area: 'jj',
          areaName: '전주시',
          address: '전주시 상세주소',
          tel: '010-1234-1234',
          lat: 35.8714318090558,
          lng: 127.078883227572,
          img: 'https://house.hyundai-motorsfc.com/UpData/introduce/%EC%98%A4%ED%86%A00-1.jpg'
        },
        {
          id: '2',
          name: '맷돌순두부',
          type: 'hyundaicar',
          typeName: '현대차',
          area: 'wj',
          areaName: '완주',
          address: '전주시 상세주소',
          tel: '010-1234-1234',
          lat: 35.8427633638066,
          lng: 127.110037321509,
          img: 'https://house.hyundai-motorsfc.com/UpData/introduce/%EC%98%A4%ED%86%A00-1.jpg'
        },
        {
          id: '3',
          name: '맷돌순두부2',
          type: 'hyundaicar',
          typeName: '현대차',
          area: 'gj',
          areaName: '김제',
          address: '전주시 상세주소',
          tel: '010-1234-1234',
          lat: 35.8427633638066,
          lng: 127.110037321509,
          img: 'https://house.hyundai-motorsfc.com/UpData/introduce/%EC%98%A4%ED%86%A00-1.jpg'
        },
        {
          id: '4',
          name: '맷돌순두부3',
          type: 'medical',
          typeName: '현대차',
          area: 'gs',
          areaName: '군제',
          address: '전주시 상세주소',
          tel: '010-1234-1234',
          lat: 35.8427633638066,
          lng: 127.110037321509,
          img: 'https://house.hyundai-motorsfc.com/UpData/introduce/%EC%98%A4%ED%86%A00-1.jpg'
        },
        {
          id: '5',
          name: '맷돌순두부100',
          type: 'food',
          typeName: '현대차',
          area: 'is',
          areaName: '익산',
          address: '전주시 상세주소',
          tel: '010-1234-1234',
          lat: 35.8427633638066,
          lng: 127.110037321509,
          img: 'https://house.hyundai-motorsfc.com/UpData/introduce/%EC%98%A4%ED%86%A00-1.jpg'
        }
      ]
      return list
    }
  },
  getters: {
  }
})

export default store
