<template>
  <div class="informationMap">
    <div class="contentsTitle colorGrey2">지도상 맵으로 찾기</div>
    <div ref="searchBox" class="searchBox">
      <input type="text" class="input" placeholder="검색어를 입력하세요" @keypress="searchHouse" v-model="word">
      <button type="button" class="btn" @click="searchHouse">검색</button>
    </div>

    <kakao-map v-bind:search-list="searchList" v-bind:type="type" :class="{mapNone: listChk}"></kakao-map>

    <div v-if="list" class="searchList">
      <div v-if="list.length" ref="searchList">
        <div class="contentsTitle colorGrey2">후원의집 검색결과</div>
        <div class="gridList houseList">
          <div
            v-for="(item, index) in list"
            :key="index"
            class="item">
            <router-link :to="{ name: 'informationDetail', params: {id: item.tr_seq} }">
              <div class="img" :style="item.orgfile && {backgroundImage: `url('https://house.hyundai-motorsfc.com/UpData/introduce/${item.orgfile}')`}">
                <div v-if="!item.orgfile" class="title">{{ item.company }}</div>
              </div>
              <div class="title">{{ item.company }}</div>
              <div class="type">{{ item.company_gbn_txt }}</div>
              <div class="area">{{ item.area_gbn }}</div>
              <div class="tel">{{ item.tel }}</div>
            </router-link>
          </div>
        </div>
      </div>
      <div v-else class="noListData">검색결과가 없습니다</div>
    </div>
  </div>
</template>

<script>
import {companyGbnStr, isEmpty, telHyphen} from '@/lib';
import http from '@/api';
import KakaoMap from '@/components/Common/KakaoMap.vue';

export default {
  name: 'InformationMap',
  components: {
    KakaoMap
  },
  created () {
    this.$store.commit('setSubTopData', {
      title: '지도상 맵으로 찾기',
      desc: '전북 후원의집 위치를 지도를 통해 확인하실 수 있습니다.'
    })
  },
  mounted () {
    this.init()
  },
  data () {
    return {
      map: null,
      type: '',
      word: '',
      searchList: null,
      list: null,
      listChk: false,
    }
  },
  methods: {
    async init () {
      let url = `/main/house/all`;
      this.type = 'all';
      this.searchAPICall(url);
    },
    searchHouse () {
      let url = `/main/house`;
      this.type = '';
      if (!isEmpty(this.word)){
        url += `?page=1&searchpart=company&searchtext=${this.word}`;
      }

      this.searchAPICall(url);
    },
    searchAPICall(url) {
      http.get(url)
        .then(res => {
          let obj = res.data.data;

          Object.entries(obj).forEach(([, value]) => {
            if(!isEmpty(value.tel) && !value.tel.includes('-')) {
              value.tel = telHyphen(value.tel)
            }

            value.company_gbn_txt = companyGbnStr(value.company_gbn);
          });

          this.searchList = obj;

          if(!url.includes('all')) {
            this.list = obj;
          }

          if(obj.length <= 0) {
            this.listChk = true;
          }else {
            this.listChk = false;
          }

          const header = document.getElementById('header')
          const headerHeight = header ? header.offsetHeight : 0
          const searchBox = this.$refs.searchBox
          const searchBoxTop = searchBox ? searchBox.offsetTop : 0

          window.scrollTo(0, searchBoxTop - headerHeight);
        });
    }
  }
}
</script>

<style scoped>
.mapNone {
  display: none;
}
</style>
