<template>
  <div :class="{openMenu: dropdownMenu}">
    <div class="dimm" @click.stop="dropdownMenu = false"></div>
    <div id="header" :class="{reverse: !isScroll, isMobile: isMobile}" @mouseleave="closeMenuIsPc">
      <!-- 상단 메뉴바 -->
      <div class="top">
        <div class="container">
          <div class="menuBox">
            <!-- 관련링크 -->
            <div class="links">
              <a
                v-for="(item, index) in links"
                :key="index"
                :href="item.link"
                :target="item.link.includes('https://') ? '_blank' : '_self'"
                class="item">
                {{ item.name }}
              </a>
            </div>
            <!-- //관련링크 -->
            <!-- 유틸링크 -->
            <div class="utils">
              <a
                v-for="(item, index) in utils"
                :key="index"
                :href="item.link"
                :target="item.link.includes('https://') ? '_blank' : '_self'"
                class="item">
                {{ item.name }}
              </a>
              <!-- 언어 설정
              <div class="item lang"
                :class="{openLang: dropdownLangs}">
                <button type="button" @click="toggleLangs">
                  {{ lang }}
                  <i></i>
                </button>
                <div
                  v-if="dropdownLangs"
                  class="langs">
                  <button type="button"
                    v-for="item in langs"
                    :key="item"
                    :class="{current: item === lang}"
                    @click.stop="setLang(item)"
                    class="langItem">
                    {{ item }}
                  </button>
                </div>
              </div>
               //언어 설정 -->
            </div>
            <!-- //유틸링크 -->
          </div>
        </div>
      </div>
      <!-- //상단 메뉴바 -->
      <!-- 메뉴 -->
      <div class="nav">
        <div class="container">
          <div class="menuBox">
            <div class="topBtns">
              <router-link :to="{name: 'main'}" class="logo"></router-link>
              <button type="button" class="btnApp" @click="toggleMenu"></button>
            </div>
            <div class="menuWrap">
              <!-- 모바일only -->
              <div class="mobileOnly">
                <div class="topUtil">
                  <a
                    v-for="(item, index) in utils.filter((x, i) => i >= 2)"
                    :key="index"
                    :href="item.link"
                    :target="item.link.includes('https://') ? '_blank' : '_self'"
                    class="item">
                    {{ item.name }}
                  </a>
                </div>
              </div>
              <!-- //모바일only -->
              <!-- GNB MENU -->
              <div class="menu" @mouseenter="dropdownMenu = true">
                <ul>
                  <li
                    v-for="(item, index) in menu"
                    :key="index">
                    <component
                      :is="isMobile ? 'div' : 'router-link'"
                      :to="{name: item.name}"
                      @click.stop="openMenuIsMobile"
                      class="menuanchor mainMenu">{{ item.meta.title }}
                    </component>
                    <ul v-if="item.children">
                      <li
                        v-for="(children, i) in item.children"
                        :key="i">
                        <router-link :to="{name: children.name, ...children.params && { params: children.params }}" class="menuanchor">{{ children.meta.title }}</router-link>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
              <!-- //GNB MENU -->
              <!-- left banner -->
              <div class="leftBanner">
                <router-link to="/information/map" class="item">
                  <div class="desc">후원의집을 한눈에</div>
                  <div class="title">후원의집 지도로 찾기</div>
                </router-link>
                <router-link to="/house/signup" class="item">
                  <div class="desc">4만 관중시대와 함께</div>
                  <div class="title">후원의집 신청하기</div>
                </router-link>
              </div>
              <!-- //left banner -->
              <!-- right banner -->
              <div class="rightBanner" :class="{autoplay: !swiperPause}">
                <div class="title">현대차 지점 소개</div>
                <button type="button" class="btnPlay" @click.stop="toggleAutoplay"></button>
                <swiper
                  ref="bannerSwiper"
                  class="swiper"
                  :options="swiperOption">
                  <swiper-slide
                    v-for="(item, index) in swiperItems"
                    :key="index"
                    class="slide">
                    <router-link :to="{name: 'informationDetail', params: {id: item.tr_seq}}" class="slideInner">
                      <div class="img"><img :src="'https://house.hyundai-motorsfc.com/UpData/introduce/'+item.orgfile" /></div>
                      <div class="name">{{ item.company }}</div>
                      <div class="tel">{{ item.tel }}</div>
                    </router-link>
                  </swiper-slide>
                </swiper>
                <div class="btns">
                  <router-link :to="{name: 'informationType', params: {type: 'hyundaicar'}}" class="btn">전북현대차 지점찾기</router-link>
                </div>
              </div>
              <!-- //right banner -->
              <!-- 모바일only -->
              <div class="mobileOnly">
                <div class="bottomUtil">
                  <a href="#">티켓예매</a>
                  <a href="#">문자신청</a>
                  <div class="item lang"
                    :class="{openLang: dropdownLangs}">
                    <button type="button" @click="toggleLangs">
                      {{ lang }}
                      <i></i>
                    </button>
                    <div
                      v-if="dropdownLangs"
                      class="langs">
                      <button type="button"
                        v-for="item in langs"
                        :key="item"
                        :class="{current: item === lang}"
                        @click.stop="setLang(item)"
                        class="langItem">
                        {{ item }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <!-- //모바일only -->
            </div>
          </div>
        </div>
      </div>
      <!-- //메뉴 -->
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import http from '@/api';
import {isEmpty, telHyphen} from '@/lib';

export default {
  name: 'HeaderView',
  components: {
    Swiper,
    SwiperSlide
  },
  computed: {
    langs () {
      const list = this.$store.state.langs
      const current = this.lang
      return [current].concat(list.filter(x => x !== current))
    },
    lang () {
      return this.$store.state.lang
    }
  },
  created () {
    const routerMenu = this.$router.options.routes && this.$router.options.routes.filter(x => {
      return x.meta && x.meta.title
    }).map(x => {
      return {
        ...x,
        ...x.children && {
          children: x.children.filter(c => {
            return c.meta && c.meta.title
          })
        }
      }
    })
    this.menu = routerMenu
    this.toggleAutoplay();
    this.houseListCalls();
  },
  mounted () {
    document.addEventListener('scroll', this.onScroll)
    window.addEventListener('resize', this.onResize)
    this.$nextTick(() => {
      this.onScroll()
      this.onResize()
    })
  },
  watch: {
    $route (to, from) {
      if (to.path !== from.path) {
        this.init()
      }
    }
  },
  data () {
    return {
      isScroll: false,
      isMobile: false,
      links: [
        {
          name: '후원의집',
          link: '/'
        },
        {
          name: '전북현대',
          link: 'https://hyundai-motorsfc.com/'
        },
        {
          name: '그린스쿨',
          link: 'https://greenschool.hyundai-motorsfc.com/'
        },
        {
          name: '초록이네',
          link: 'https://shop.hyundai-motorsfc.com/'
        },
        {
          name: '인권침해 접수처',
          link: 'https://hyundai-motorsfc.com/ethics'
        }
      ],
      utils: [
        /*{
          name: '신청하기',
          link: '/house/signup'
        },
        {
          name: '사이트맵',
          link: '/'
        },
        {
          name: '로그인',
          link: '/'
        },*/
        {
          name: '통합회원가입',
          link: 'https://hyundai-motorsfc.com/member/signup/1'
        }
      ],
      menu: [],
      dropdownMenu: false,
      dropdownLangs: false,
      swiperItems: [],
      swiperPause: false,
      swiperOption: {
        slidesPerView: 2,
        spaceBetween: 16,
        slidesPerGroup: 2,
        loop: true,
        autoplay: {
          delay: 3000,
          disableOnInteraction: false
        }
      }
    }
  },
  methods: {
    init () {
      this.dropdownMenu = false;
      this.dropdownLangs = false;
    },
    onScroll () {
      const height = this.isMobile ? 80 : 130
      this.isScroll =  window.scrollY >= height
    },
    onResize () {
      this.onScroll()
      this.isMobile = window.innerWidth < 960
    },
    getTitle (item) {
      const lang = this.lang
      return item.meta && lang === 'ENG'
        ? item.meta.enTitle || item.meta.title
        : item.meta.title || item.name
    },
    toggleLangs () {
      this.dropdownLangs = !this.dropdownLangs
    },
    setLang (lang) {
      // 영문은 준비가 아직 안되었으므로 우선 얼럿처리
      if (lang === 'ENG') {
        alert('준비중입니다.')
      }
      /*
      if (lang !== this.lang) {
        this.$store.commit('setLang', lang)
      }
      */
    },
    toggleMenu () {
      this.dropdownMenu = !this.dropdownMenu
    },
    closeMenuIsPc (e) {
      if (this.isMobile) {
        e.preventDefault()
      } else {
        this.dropdownMenu = false
      }
    },
    openMenuIsMobile (e) {
      if (this.isMobile) {
        const toggle = e.target.classList.contains('open') ? 'remove' : 'add'
        e.preventDefault()
        e.target.classList[toggle]('open')
      }
    },
    toggleAutoplay () {
      const swiper = this.$refs.bannerSwiper
      if (swiper) {
        if (this.swiperPause) {
          swiper.$swiper.autoplay.run()
        } else {
          swiper.$swiper.autoplay.pause()
        }
        this.swiperPause = !this.swiperPause
      }
    },
    houseListCalls() {
      let url = `/main/house?page=1&searchpart=company_gbn&searchtext=C12`;

      http.get(url)
        .then(res => {
          let obj = res.data.data;

          Object.entries(obj).forEach(([, value]) => {
            if(!isEmpty(value.tel) && !value.tel.includes('-')) {
              value.tel = telHyphen(value.tel)
            }
          });

          this.swiperItems = obj;
        });
    },
  }
}
</script>
