<template>
  <div class="informationDetail">
    <div class="contentsTitle colorGrey2">후원의집 상세정보
      <span class="goList" @click.stop="goBack">목록으로</span>
    </div>
    <div>
      <div class="benefitBox">
        <div class="label">혜택</div>
        {{contents.benefit}}
      <!--<div v-if="contents.benefit">{{contents.benefit}}</div>-->
      </div>
      <div v-if="contents" v-html="contents.content"></div>
      <div v-else>내용 준비 중입니다.</div>
      <div class="btns">
        <button type="button" class="btn viewmore" @click.stop="goBack">이전페이지</button>
      </div>
      <div v-if="contents" class="contentsTitle colorGrey2">오시는 길</div>
      <div v-if="contents" class="info">
        <kakao-map v-bind:search-list="mapList"></kakao-map>

        <div v-if="contents" class="information">
          <div class="label">주소</div>
          <p v-if="contents.mapaddr">
            <span>지번</span>
            {{ contents.mapaddr }}
          </p>
          <p v-if="contents.newaddr">
            <span>도로명</span>
            {{ contents.newaddr }}
          </p>
          <div class="label">연락처</div>
          <p v-if="contents.tel">
            <span>전화번호</span>
            <a :href="`tel:${contents.tel}`">{{ contents.tel }}</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import http from '@/api';
import {areaGbnCode, companyGbnStr, htmlConverter, isEmpty, telHyphen} from '@/lib';
import KakaoMap from '@/components/Common/KakaoMap.vue';

export default {
  name: 'InformationDetail',
  components: {
    KakaoMap
  },
  created () {
    this.$store.commit('setSubTopData', {
      title: '전체현황',
      desc: '전북 후원의집 소개합니다.'
    })
  },
  watch: {
    $route() {
      // this.detailView(to.params.id);
      this.$router.go(0);
    }
  },
  mounted () {
    this.init()
  },
  data () {
    return {
      contents: {},
      mapList: null,
      tags: null,
      map: null
    }
  },
  methods: {
    init () {
      const params = this.$route.params.id;
      this.detailView(params);
    },
    goBack () {
      this.$router.go(-1)
    },
    detailView(params) {
      let url = `/main/house/${params}`;

      http.get(url)
        .then(res => {
          let obj = res.data.data;

          if(isEmpty(obj.detail)) {
            return;
          }
          obj.detail.company_gbn_txt = companyGbnStr(obj.detail.company_gbn);
          obj.detail.area_gbn_txt = obj.detail.area_gbn;
          obj.detail.area_gbn = areaGbnCode(obj.detail.area_gbn);

          // 해시태그 추가
          if (obj.detail.company_gbn_txt || obj.detail.area_gbn) {
            this.tags = []
            if (obj.detail.company_gbn_txt) {
              let result = {};
              result.gbn_code = obj.detail.company_gbn;
              result.gbn_txt = obj.detail.company_gbn_txt;
              result.type = 'type';
              this.tags.push(result)
            }
            if (obj.detail.area_gbn) {
              let result = {};
              result.gbn_code = obj.detail.area_gbn;
              result.gbn_txt = obj.detail.area_gbn_txt;
              result.type = 'area';
              this.tags.push(result)
            }
          }

          obj.detail.content = htmlConverter(obj.detail.content);

          if(!isEmpty(obj.detail.tel) && !obj.detail.tel.includes('-')) {
            obj.detail.tel = telHyphen(obj.detail.tel);
          }

          this.contents = obj.detail;

          // 카카오맵 호출
          this.mapList = Array(obj.detail);

          // 상단
          this.$store.commit('setSubTopData', {
            title: this.contents.company,
            desc: this.contents.simple_introduce,
            tags: this.tags,
            ...this.contents.orgfile && {
              bgImg: this.contents.orgfile
            }
          })
        });
    }
  }
}
</script>
