<template>
  <div class="houseSignup">
    <div class="contentsBox">
      <div class="contentsTitle colorGrey2">후원의집 가입 신청하기</div>
      <div class="contentsInner">
        <div class="formTable">
          <table>
            <tr>
              <th>상호명<span>(필수)</span></th>
              <td><input type="text" class="input" v-model="applyData.company" placeholder="상호명을 입력하세요"></td>
            </tr>
            <tr>
              <th>대표자명<span>(필수)</span></th>
              <td><input type="text" class="input" v-model="applyData.ceo" placeholder="대표자명을 입력하세요"></td>
            </tr>
            <tr>
              <th>사업장 전화번호<span>(필수)</span></th>
              <td>
                <!--<input type="text" class="input" v-model="applyData.tel" placeholder="업장 전화번호를 입력하세요">-->
                <input-phone v-model="applyData.tel" :label="'사업장 전화번호를 입력하세요'" />
              </td>
            </tr>
            <tr>
              <th>대표 연락처<span>(필수)</span></th>
              <td>
                <input-phone v-model="applyData.ceo_tel" :label="'대표 연락처를 입력하세요'" />
              </td>
            </tr>
            <tr>
              <th>사업장주소<span>(필수)</span></th>
              <td><input type="text" class="input" v-model="applyData.addr" placeholder="사업장주소를 입력하세요"></td>
            </tr>
            <tr>
              <th class="top">업소간단소개<br>구단에게 하고 싶은 말</th>
              <td><textarea class="input textarea" v-model="applyData.simple_introduce" placeholder="내용을 입력하세요"></textarea></td>
            </tr>
            <tr>
              <th>첨부파일 이미지1</th>
              <td>
                <div class="inputWithBtn uploadInput">
                  <input type="text" class="input" v-model="pic1Nm" placeholder="첨부파일 이미지를 등록하세요" readonly>
                  <label for="upload_file_1" class="btn">첨부파일 등록</label>
                  <input type="file" id="upload_file_1" @change="fileSelectFrst" accept="image/*" ref="upload_file_1">
                </div>
              </td>
            </tr>
            <tr>
              <th>첨부파일 이미지2</th>
              <td>
                <div class="inputWithBtn uploadInput">
                  <input type="text" class="input" v-model="pic2Nm" placeholder="첨부파일 이미지를 등록하세요" readonly>
                  <label for="upload_file_2" class="btn">첨부파일 등록</label>
                  <input type="file" id="upload_file_2" @change="fileSelectSec" accept="image/*" ref="upload_file_2">
                </div>
              </td>
            </tr>
          </table>
        </div>
        <div class="conteintsInfo">
          <p class="paragraph">※ 상기 기입된 정보들에 대한 모든 권리 및 책임은 구단에 있으며, 구단에서는 홈경기 홍보 알림 등 구단 업무목적으로 사용할 수 있음을 알려드립니다.</p>
          <div class="btnViewmore">
            <!--<router-link :to="{name: 'informationMap'}" class="btn viewmore">신청하기</router-link>-->
            <button type="button" class="btn viewmore" @click="toAply()">신청하기</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import http from '@/api';
import {isEmpty} from '@/lib';
import InputPhone from '@/components/Common/InputPhone.vue';
import axios from "axios";

export default {
  name: 'HouseSignup',
  components: {
    InputPhone
  },
  created () {
    this.$store.commit('setSubTopData', {
      title: '후원의집 가입 신청하기',
      desc: '전북현대모터스 구단과 10만 팬들이 함께할 후원의집을 모집합니다.'
    })
  },
  data() {
    return {
      applyData: {
        company: '',
        ceo: '',
        tel: '',
        ceo_tel: '',
        addr: '',
        simple_introduce: '',
        pic1: '',
        pic2: ''
      },
      pic1Nm: '',
      pic2Nm: ''
    }
  },
  methods: {
    fileSelectFrst(event) {
      this.applyData.pic1 = this.fileValidation(event.target.files);
      this.pic1Nm = this.applyData.pic1[0].name;
    },
    fileSelectSec(event) {
      this.applyData.pic2 = this.fileValidation(event.target.files);
      this.pic2Nm = this.applyData.pic2[0].name;
    },
    fileValidation(file) {
      let validation = true;
      let message = '';

      if (file.length > 1) {
        validation= false;
        message = `파일은 한개만 등록 가능합니다.`
      }

      if (file[0].type.indexOf('image') < 0) {
        message = `${message}, 이미지 파일만 업로드 가능합니다.`;
        validation = false;
      }

      if (!validation) {
        alert(message);
        return false;
      }

      return file;
    },
    toAply() {
      // 필수값 체크
      if (!this.isMstValid()) {
        return;
      }

      this.applyAdd();
    },
    isMstValid() {
      if (isEmpty(this.applyData.company)) {
        alert('상호명은 입력 필수입니다.')
        return false;
      }

      if (isEmpty(this.applyData.ceo)) {
        alert('대표자명은 입력 필수입니다.')
        return false;
      }

      if (isEmpty(this.applyData.tel)) {
        alert('사업장 전화번호는 입력 필수입니다.')
        return false;
      }

      // if(!this.telNumChk(this.applyData.tel)){
      //   alert('사업장 전화번호를 올바르게 작성하세요.')
      //   return false;
      // }

      if (isEmpty(this.applyData.ceo_tel)) {
        alert('대표 연락처는 입력 필수입니다.')
        return false;
      }

      // if(!this.telNumChk(this.applyData.ceo_tel)){
      //   alert('대표 연락처를 올바르게 작성하세요.')
      //   return false;
      // }

      if (isEmpty(this.applyData.addr)) {
        alert('사업장 주소는 입력 필수입니다.')
        return false;
      }

      // const addrRule = /(([가-힣A-Za-z·\d~\-.]{2,}(로|길).[\d]+)|([가-힣A-Za-z·\d~\-.]+(읍|동)\s)[\d]+)/;
      // if(!addrRule.test(this.applyData.addr)) {
      //   alert('사업장 주소를 올바르게 작성하세요.')
      //   return false;
      // }

      return true;
    },
    applyAdd() {
      if(!confirm('신청하시겠습니까?')) {
        return false;
      }

      try {
        let formData = new FormData()
        formData.append('company', this.applyData.company.trim())
        formData.append('ceo', this.applyData.ceo.trim())
        formData.append('tel', this.applyData.tel.trim())
        formData.append('ceo_tel', this.applyData.ceo_tel.trim())
        formData.append('addr', this.applyData.addr.trim())
        formData.append('simple_introduce', this.applyData.simple_introduce)
        this.$refs.upload_file_1.files.length > 0 && formData.append('pic1', this.$refs.upload_file_1.files[0]);
        this.$refs.upload_file_2.files.length > 0 && formData.append('pic2', this.$refs.upload_file_2.files[0]);
        for (let key of formData.keys()) {
          // console.log(key, ":", formData.get(key));
        }

        axios.post('https://house-api.jbfc.kr/apply/house', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then(({ data }) => {
          // console.log(data);
          if(data.success !== true) {
            alert(data.msg)
            return false;
          }
          alert('신청이 완료되었습니다.')
          console.log(data)
          this.$router.go(0);
        }).catch(err => {
          console.log(err)
        });





      } catch (e) {
        console.log(e);
      }
    },
    telNumChk(tel) {
      return true;
    }
  }
}
</script>
