<template>
  <div class="houseBenefit">
    <div class="contentsBox">
      <div class="contentsTitle colorGrey2">후원의집 지원</div>
      <div class="contentsInner">
        <div class="graphicBox">
          <img src="@/assets/img/img_benefit_1.png" alt="">
        </div>
        <div class="paragraph">
          <p class="bold">① 구단제작 후원의집 공식 현판(아크릴 제작) 제공 (45*65cm)</p>
          <p>가입해지 및 종료시 후원의집 현판만은 구단에 반납 해주셔야 합니다.</p>
        </div>
        <div class="graphicBox">
          <img src="@/assets/img/img_benefit_2.png" alt="">
          <img src="@/assets/img/img_benefit_3.png" alt="">
        </div>
        <div class="paragraph">
          <p class="bold">② 매월 홈경기 일정 홍보용 포스터 4매 제공(구단 제작)</p>
        </div>
      </div>
    </div>
    <div class="contentsBox">
      <div class="contentsTitle colorGrey2">후원의집 추가 지원</div>
      <div class="contentsInner">
        <div class="graphicBox">
          <img src="@/assets/img/img_benefit_4.png" alt="">
          <img src="@/assets/img/img_benefit_5.png" alt="">
          <img src="@/assets/img/img_benefit_6.png" alt="">
          <img src="@/assets/img/img_benefit_7.png" alt="">
        </div>
        <ul>
          <li>① 구단 후원의집 홈페이지에 업체 정보 등록 홍보</li>
          <li>② 후원의집 이벤트 실시업체 구단 메인 홈페이지 통해 직접 홍보</li>
          <li>③ 선수단 팬 싸인회 및 팬미팅 등 선수단을 활용한 업소 마케팅 실시 고려</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HouseBenefit',
  created () {
    this.$store.commit('setSubTopData', {
      title: '후원의집 혜택',
      desc: '전북 후원의집 가입시 누릴 수 있는 혜택에 대해 알려드립니다.'
    })
  }
}
</script>
