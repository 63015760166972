<template>
  <div class="house">
    <div class="container">
      <div class="houseBox">
        <div class="sectionTitle">
          <h2 class="title">전북현대와 함께하는 후원의집</h2>
          <p class="desc">후원의집은 팬 여러분들 곁에 늘 있습니다.</p>
          <router-link :to="{name: 'informationMap'}" class="btn primary viewmore">후원의집 지도 검색하기</router-link>
        </div>
        <div class="gridList">
          <div
            v-for="(item, index) in list"
            :key="index"
            class="item">
            <router-link :to="{name: 'informationDetail', params: {id: item.tr_seq}}">
              <div class="img" :style="item.orgfile && {backgroundImage: `url('https://house.hyundai-motorsfc.com/UpData/introduce/${item.orgfile}')`}">
                <div class="title">{{ item.company }}</div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import http from '@/api';
import {isEmpty, telHyphen} from '@/lib';

export default {
  name: 'MainHouse',
  mounted () {
    this.init()
  },
  data () {
    return {
      list: []
    }
  },
  methods: {
    init () {
      this.houseListCalls();
    },
    houseListCalls() {
      let url = `/main/house?page=1`;

      http.get(url)
        .then(res => {
          let obj = res.data.data;
          this.list = obj;
        });
    }
  }
}
</script>
