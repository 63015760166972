<template>
  <div class="premium">
    <div class="container">
      <div class="sectionTitle">
        <h2 class="title">금주의 프리미엄 후원의집</h2>
        <p class="desc">이번주 베스트 후원의집을 알려드립니다.</p>
      </div>
      <div class="gridList">
        <div
          v-for="(item, index) in list"
          :key="index"
          class="item">
          <router-link :to="{name: 'informationDetail', params: {id: item.house_seq}}">
            <div class="img">
              <div v-if="!item.orgfile" class="text">
                <div class="title">{{ item.house_name }}</div>
              </div>
              <!--<img v-else :src="item.orgfile" alt="">-->
              <img v-else :src="'https://house.hyundai-motorsfc.com/UpData/introduce/'+item.orgfile" alt="">
            </div>
            <div class="title">{{ item.house_name }}</div>
            <!--<div class="desc">{{ item.address }}</div>-->
          </router-link>
        </div>
      </div>
      <div class="btnViewmore">
        <router-link :to="{name: 'informationAll'}" class="btn viewmore">후원의집 더보기</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import http from '@/api';

export default {
  name: 'MainPremium',
  mounted () {
    this.init()
  },
  data () {
    return {
      list: []
    }
  },
  methods: {
    init () {
      this.houseListCalls();
    },
    houseListCalls() {
      http.get(`/main/house/best`)
        .then(res => {
          let obj = res.data.data;
          this.list = obj;
        });
    }
  }
}
</script>
