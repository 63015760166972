<template>
  <div class="accordion">
    <div v-if="isList">
      <div
        v-for="(item, index) in list"
        :key="index"
        :class="{active: item.active}"
        class="item">
        <div class="question" @click="toggle(item)">{{ item.question }}</div>
        <transition name="accordion"
          @before-enter="beforeEnter"
          @enter="enter"
          @leave="leave"
          @before-leave="beforeLeave">
          <div class="answer" v-show="item.active">
            <div v-html="item.answer"></div>
          </div>
        </transition>
      </div>
    </div>
    <div v-else class="noData">데이터가 없습니다.</div>
  </div>
</template>

<script>
export default {
  name: 'AccordionList',
  props: [
    'list'
  ],
  computed: {
    isList () {
      const list = this.list
      return list && list.length && list.find(x => x.answer && x.question)
    }
  },
  methods: {
    toggle (item) {
      item.active = !item.active;
    },
    beforeEnter (el) {
      el.style.height = '0'
      el.style.paddingTop = '0'
      el.style.paddingBottom = '0'
    },
    enter (el) {
      el.style.height = el.scrollHeight + 42 + 'px'
      el.style.paddingTop = '10px'
      el.style.paddingBottom = '32px'
    },
    beforeLeave (el) {
      el.style.height = el.scrollHeight + 42 + 'px'
      el.style.paddingTop = '10px'
      el.style.paddingBottom = '32px'
    },
    leave (el) {
      el.style.height = '0'
      el.style.paddingTop = '0'
      el.style.paddingBottom = '0'
    }
  }
}
</script>
