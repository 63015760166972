<template>
  <div class="partnerPromotion">
    <div v-if="!isView">
      <div class="contentsTitle colorGrey2">홍보 및 제휴사항
        <div class="listTotal">총 <span class="bold colorPrimary2">{{noticeCount}}</span>개의 홍보 및 제휴사항 게시물이 있습니다</div>
      </div>
      <div v-if="list && list.length" class="listTable">
        <table>
          <tbody>
            <tr
              v-for="(item, index) in list"
              :key="index"
              :class="{notice: item.isNotice}">
              <td class="num">{{ item.seq }}</td>
              <td class="subject">
                <router-link :to="{name: 'partnerPromotionView', params: { id: item.seq }}">
                  {{ item.title }}
                </router-link>
              </td>
              <td class="date">{{ item.reg_date }}</td>
              <td class="view">{{ comma(item.read_count) }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-else class="noListData">게시글이 없습니다</div>
      <Pagination v-bind:total="noticeCount" v-bind:pageDisplayCount="pageCount" @onChange="pageChange" />
      <ListFilter
        :types="searchTypes"
        @onSearch="searchList" />
    </div>
    <router-view v-else></router-view>
  </div>
</template>

<script>
import regExp from '@/common/regExp'
import Pagination from '@/components/Common/Pagination'
import ListFilter from '@/components/Common/ListFilter'
import {isEmpty} from '@/lib';
import http from '@/api';
import dayjs from 'dayjs';

export default {
  name: 'PartnerMarketing',
  components: {
    Pagination,
    ListFilter
  },
  computed: {
    isView () {
      return this.$route.params.id
    }
  },
  created () {
    this.$store.commit('setSubTopData', {
      title: '홍보 및 제휴사항',
      desc: '후원의 집에서 홍보하고자 하는 내용과 제휴사항을 알려드립니다.'
    })

    this.init()
  },
  watch: {
    $route() {
      // this.detailView(to.params.id);
      this.$router.go(0);
    }
  },
  mounted () {
  },
  data () {
    return {
      notice_gbn: 'E',
      pageNum: 1,
      noticeCount: 0,
      pageCount: 0,
      list: [],
      searchTypes: [
        {
          type: 'subject',
          name: '제목'
        }
      ],
      searchpart: '',
      searchtext: ''
    }
  },
  methods: {
    init () {
      this.noticeListCalls();
    },
    comma (str) {
      return regExp.comma(`${str}`)
    },
    pageChange (page) {
      this.pageNum = page;
      this.noticeListCalls();
    },
    searchList (data) {
      this.searchpart = data.type
      this.searchtext = data.word;

      this.noticeListCalls('search');
    },
    noticeListCalls(key) {
      let url = `/main/notice?notice_gbn=${this.notice_gbn}&page=${this.pageNum}`;

      if (!isEmpty(this.searchtext)){
        url += `&searchpart=${this.searchpart}&searchtext=${this.searchtext}`;
      }

      http.get(url)
        .then(res => {
          let obj = res.data.data;
          this.noticeCount = res.data.total;
          this.pageCount = res.data.per_page;

          Object.entries(obj).forEach(([, value]) => {
            value.reg_date = dayjs(value.reg_date).format("YYYY-MM-DD");
          });

          this.list = obj;
        });
    }
  }
}
</script>
